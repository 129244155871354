import axios from 'axios';
import freetier from '../store/freetier';
let API_URL = process.env.VUE_APP_AXPARA_API;

const url = API_URL;
axios.defaults.withCredentials = true;

const  AuthService = {

        async loginUser(payload) {
            return await axios.post(API_URL+'free-client/auth/login/get-email', {
                    email: payload.email
                }
            )
        },       
        async loginOTP(payload) {

            let param = {
                email: payload.email,
                password: payload.otp,
            } 
            return await axios.post(API_URL+'free-client/auth/login', param);

        },
        async checkLogin(){
            return await axios.get(API_URL+'auth/me/client');
        },
        async logout(){
            return await axios.post(API_URL+'auth/logout/client');
        },
        async verifyPhone(payload) {
            return await axios.post(API_URL+'free-client/auth/phone-verification/get-sms', { 
                     phone_number: payload.phoneNumber
               }
           )
        },
        async verifyPhoneOTP(payload) {
            return await axios.post(API_URL+'free-client/auth/phone-verification/verify',{ 
                    phone_number: payload.phoneNumber,
                    password: payload.otp,
               }
           )
        },
        async verifyToken(token) {
            return await axios.post(API_URL+'free-client/auth/verify', null, {headers: {"Authorization": `Bearer ${token}`}})
        }

}
export default AuthService;
