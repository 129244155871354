import { createStore } from "vuex";
import skills from "./modules/skills";
import freetier from "../modules/freetier/store/freetier";
import talent from "../modules/talent/store/talent";
import client from "../modules/client/store/client";
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
export const store = new createStore({
    modules: {
        skills,
        freetier,
        talent,
        client,
    },
    state: {},
    getters: {},
    actions: {},
    mutations: {},
    plugins: [
        createPersistedState({})
    ]
});