const Module = () => import("./Module.vue");

const moduleRoute = {
    path: '/talent',
    name: 'Talent',
    meta: { isTalentAccess: true, access: 'talent' },
    component: Module,
    children: [{
        path: '',
        name: 'TalentLanding',
        meta: { isTalentAccess: true, access: 'talent' },
        component: () =>
            import('./views/AboutUs.vue'),
    },
    {
        path: 'auth/verify/:token',
        name: 'TPVerifyToken',
        meta: { requiresAuth: false, isTalentAccess: true, access: 'talent'},
        component: () =>
        import ('./views/authentication/TPVerifyLogin.vue')
    },
    {
        path: 'v2',
        name: 'TalentVersion2',
        meta: { guest: false, isTalentAccess: true, access: 'talent' },
        children: [           
            {
                path: 'about-us',
                name: 'TalentAboutUs',
                meta: { isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/AboutUs.vue'),
            },
            {
                path: 'home',
                name: 'TalentHome',
                meta: { requiresAuth: false, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/TalentHomeV2.vue'),
            },
            {
                path: 'joblist',
                name: 'TalentJoblist',
                meta: { requiresAuth: false, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/JobList.vue'),
            },
            {
                path: 'jobdetails',
                name: 'TalentJobDetails',
                meta: { requiresAuth: false, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/JobDetails.vue'),
            },
            {
                path: 'search-result',
                name: 'SearchResult',
                meta: { requiresAuth: false, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/SearchResult.vue'),
            },
            {
                path: 'no-result',
                name: 'NoResultFound',
                meta: { requiresAuth: false, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/NoResult.vue'),
            },
            {
                path: 'save-jobs',
                name: 'SaveJobs',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/SaveJobs.vue'),
            },
            {
                path: 'my-applications',
                name: 'MyApplication',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/MyApplications.vue'),
            },
            {
                path: 'my-attendance',
                name: 'MyAttendance',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/attendance/MyAttendance.vue'),
            },
            {
                path: 'public-holiday',
                name: 'PublicHoliday',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/PublicHoliday.vue')
            },
            // {
            //     path: 'my-appraisals',
            //     name: 'MyAppraisals',
            //     meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
            //     component: () =>
            //         import('./views/MyAppraisals.vue')
            // },
            {
                path: 'my-documents',
                name: 'MyDocuments',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/MyDocuments.vue')
            },
            {
                path: 'my-cv',
                name: 'MyCV',
                meta: { requiresAuth: true, isTalentAccess: true, access: 'talent' },
                component: () =>
                    import('./views/MyCV.vue')
            }, 
        ]
    },
    ]
}
export default router => {
    router.addRoute(moduleRoute);
};