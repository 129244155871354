const Module = () => import("./Module.vue");

const moduleRoute =  {
    path: '/free',
    name: 'FreeTier',
    meta: { guest: true, isFreeTierAccess: true, access: 'free-tier' },
    component: Module,
    children: [
        {
            path: 'auth/verify/:token',
            name: 'FTVerifyToken',
            meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Any'},
            component: () =>
            import ('./views/authentication/FTVerifyLogin.vue')
        },
        {
            path: 'pdf-viewer/:id',
            name: 'FreeTierPDFViewer',
            meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Any'},
            component: () =>
            import ('./views/pdf/PDFViewer.vue')
        },
        {
            path: 'login',
            name: 'FreeTierLogin',
            // meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            // component: () =>
            // import ('./views/authentication/Login.vue')
            redirect: to => {
                return 'free/home'
            },
        },
        {
            path: 'my-orders',
            name: 'TierMyOrder',
            meta: { requiresAuth: true, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            component: () =>
                import ('./views/MyOrder.vue')
        },
        {
            path: 'job-details/:id',
            name: 'JobDetails',
            meta: { requiresAuth: true, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            component: () =>
                import ('./views/JobDetails.vue')
        },
        {
            path: 'home',
            name: 'FreeTierAbout',
            meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            component: () =>
                import ('./views/FTHome.vue')
        },
        {
            path: '',
            name: 'FreeTierLanding',
            meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            component: () =>
                import ('./views/FTHome.vue')
        },
        {
            path: 'candidatedb',
            name: 'FTCandidateDatabase',
            meta: { requiresAuth: false, isFreeTierAccess: true, access: 'freeTierclient', location: 'Phillipines' },
            component: () =>
                import ('./views/CandidateDatabase.vue')
        }
    ]
}  
 
export default router => {   
    router.addRoute(moduleRoute);   
};